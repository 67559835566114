import ProtectedArticleGraphql from './protected-article.graphql';
import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import ArticleDetails from './articleDetailsTemplate';
import { parse } from 'flatted';

interface PageContext {
	pageContext: {
		id: string;
	};
}

export interface QueryResultArticle {
	contentful_id: string;
	articleType?: string;
	title?: string;
	publicationDate?: string;
	externalArticleUrl?: string;
	content: {
		json: string;
	};
}

interface QueryResult {
	contentfulArticle: QueryResultArticle;
}

const ProtectedArticleDetails = (props: PageContext) => {
	const { id } = props.pageContext;
	const { data, loading, error, refetch, networkStatus } = useQuery<QueryResult>(
		ProtectedArticleGraphql,
		{
			variables: {
				id,
			},
			notifyOnNetworkStatusChange: true,
		}
	);

	let article = undefined;

	if (data) {
		article = JSON.parse(JSON.stringify(data.contentfulArticle));
		if (article.content?.json) {
			article.content = {
				json: parse(article.content.json), // parses circular references
			};
		}
	}

	return (
		<ArticleDetails
			pageContext={{
				article,
				loading,
				error,
				refetch,
			}}
		/>
	);
};

export default class ProtectedArticleDetailsComponent extends React.Component<PageContext> {
	render() {
		return <ProtectedArticleDetails {...this.props} />;
	}
}
